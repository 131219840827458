import Link from "next/link";
import Button from "../app/component/commons/button/Button";
import Image from "../app/component/commons/image/Image";
import Meta from "../app/component/commons/meta/Meta";
import DefaultLayout from "../app/layouts/DefaultLayout";

const NotFound404 = () => {
  return (
    <>
      <Meta title="404 - Not Found" description="Not found page" />
      <DefaultLayout
        noFooter
        containerClassName="flex flex-col h-screen"
        mainClassName="h-full flex content-center"
      >
        <div className="container mx-auto flex flex-col justify-center items-center text-center">
          <Image
            src={`/images/illustration/404.svg`}
            alt="404"
            className="w-[14rem]"
          />
          <div className="text-title3 text-neutral-900 font-bold mt-[28px[">
            Ooops, Page Not Found
          </div>
          <p className="w-auto sm:w-[330px] text-neutral-600 text-regular-tight mt-[18px]">
            his page doesn’t exist or was removed!
            <br />
            we suggest you back to home
          </p>
          <div className="col-span-full mt-7">
            <Link href="/">
              <Button type="submit" size="lg" className="w-auto">
                Back to Home
              </Button>
            </Link>
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};

export default NotFound404;
